html {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "proxima-nova", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input::placeholder {
  color: #080a14 !important;
  opacity: 1 !important;
}

::-moz-selection {
  background: #f47321;
  color: #fff;
}
::selection {
  background: #f47321;
  color: #fff;
}
